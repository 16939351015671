import React from 'react'

const CNBVLogo = () => (
  <svg
    width="62"
    height="48"
    viewBox="0 0 62 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.8377 3.27233H14.1631L16.2027 0.19458H39.6694L41.8377 3.27233Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0793 0L13.7822 3.4665H42.221L39.7787 0H16.0793ZM16.3114 0.388477H39.5457L41.4401 3.07767H14.5295L16.3114 0.388477Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.3954 44.7163H20.7207L22.7603 47.7941H46.227L48.3954 44.7163Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3457 44.5222L22.643 47.9887H46.3422L48.7844 44.5222H20.3457ZM21.0943 44.9118H48.0047L46.1103 47.601H22.876L21.0943 44.9118Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.345 47.7947L6.02656 25.9492L4.22656 29.1516L16.3678 47.6756L20.345 47.7947Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.98633 29.1581L16.2479 47.8656L20.7272 47.9999L6.01348 25.5513L3.98633 29.1581ZM16.4891 47.4844L4.46777 29.1438L6.04082 26.3464L19.9639 47.5884L16.4891 47.4844Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.90099 27.2565L16.9179 5.24536L13.0079 5.30873L1.12207 23.9731L2.90099 27.2565Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8855 5.11504L0.939785 23.8735L0.881836 23.9644L2.88539 27.6624L17.2894 5.0437L12.8855 5.11504ZM1.36348 23.9791L13.1317 5.49964L16.5475 5.44424L2.91798 26.8479L1.36348 23.9791Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.3657 22.1683L42.124 0.280029H46.0346L58.274 19.0814L56.3657 22.1683Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.7549 0.0866699L56.3736 22.5544L58.521 19.0801L46.2184 0.180942L46.1571 0.0866699H41.7549ZM42.4986 0.474609H45.9176L58.0323 19.0842L56.3636 21.7837L42.4986 0.474609Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.6279 42.7903L59.5066 20.7651L61.3982 24.0153L49.4797 42.7903H45.6279Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.2588 42.984H49.5978L61.637 24.0187L59.5112 20.3662L45.2588 42.984ZM59.4958 21.1635L61.1532 24.0111L49.3554 42.5958H45.9908L59.4958 21.1635Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4403 24.5007C12.4976 26.2849 13.8522 28.7661 16.5758 28.7041C17.5986 28.681 18.8362 28.439 20.239 27.4632C20.0042 28.0349 19.6576 29.0258 19.2319 30.0987C18.1298 30.6401 16.7504 30.7215 15.4505 30.7215C12.0115 30.7215 9.22363 27.6135 9.22363 23.7794C9.22363 19.9451 12.2148 17.4546 15.6538 17.4546C17.0282 17.4748 17.9775 17.5654 18.6151 17.7024C18.6276 18.8131 18.5981 19.347 18.604 19.9815C17.3169 19.2343 16.2462 19.1519 15.4056 19.2084C13.031 19.3678 12.3234 22.0063 12.4403 24.5007Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.9227 30.6006C30.9227 30.6006 28.2505 31.3886 24.1522 23.9629C23.2341 22.1632 22.6605 22.708 22.6605 22.708C22.6605 22.708 22.7001 27.5925 22.7096 29.0447C22.7096 29.559 23.4302 30.2541 23.4302 30.2541H20.6057C20.6057 30.2541 20.9555 29.6496 20.9555 29.1354C20.9555 28.7492 21.0432 22.632 20.9555 19.4716C20.8889 18.4523 20.2393 17.7627 20.2393 17.7627H23.5952L28.8332 25.3424C28.8332 25.3424 29.3983 25.5651 29.3265 24.8949C29.281 24.4716 29.5003 18.6689 28.7278 17.7779H31.5901C31.5901 17.7779 30.9726 18.1666 30.9754 19.0574C30.9781 19.9485 30.9227 30.6006 30.9227 30.6006Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.4291 28.4405C36.7415 28.8312 35.5518 28.6277 35.5518 28.6277V26.3933V24.275C35.5518 24.275 36.5561 24.0785 37.1627 24.3691C37.9152 24.7293 38.3931 25.6498 38.3931 26.3933C38.3931 27.0651 38.1165 28.0498 37.4291 28.4405ZM35.5518 20.829V19.4159C35.5518 19.4159 36.4941 19.2623 36.9366 19.5747C37.5684 20.0206 37.4967 20.3819 37.4967 20.988C37.4967 21.5355 37.215 22.1793 36.6547 22.4981C36.0944 22.8163 35.5518 22.6505 35.5518 22.6505V20.829ZM41.4104 26.3222C41.3051 23.9287 38.7821 23.1941 38.7821 23.1941C38.7821 23.1941 40.5743 22.2814 40.7256 20.7646C40.8767 19.2475 39.2768 17.7627 38.0239 17.7627H31.8965C31.8965 17.7627 32.3793 18.0206 32.3793 18.4603V29.4503C32.3793 29.8346 31.8965 30.1588 31.8965 30.1588H38.3022C39.5666 30.1588 41.5158 28.7162 41.4104 26.3222Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.5623 30.6247C47.5623 30.6247 44.86 30.0259 44.3709 28.6802C43.8821 27.3343 40.4043 17.7627 40.4043 17.7627H43.9834L46.5483 26.6247C46.5483 26.6247 46.9957 27.6702 47.5623 26.0468C47.9587 24.9118 49.2089 21.0657 49.4114 19.004C49.4985 18.1168 48.6958 17.7627 48.6958 17.7627H51.6487L47.5623 30.6247Z"
      fill="#A5AEA7"
    />
  </svg>
)

export default CNBVLogo
