import React from 'react'

const CondusefLogo = () => (
  <svg
    width="135"
    height="48"
    viewBox="0 0 135 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.009 40.5959L67.3236 40.6047C67.4578 40.6088 67.5919 40.6128 67.7273 40.6128H67.8802L67.9896 40.7111C72.7296 44.9638 78.9799 47.3059 85.5886 47.3059C99.5128 47.3059 110.841 36.8509 110.841 24.0002C110.841 11.1493 99.5128 0.694336 85.5886 0.694336C78.9105 0.694336 72.6137 3.0788 67.8579 7.40827L67.7491 7.50731L67.5964 7.50824C67.5357 7.5087 67.4753 7.51009 67.4151 7.51194L67.1338 7.51842L67.0207 7.41498C62.2642 3.08134 55.9641 0.694336 49.2818 0.694336C35.3581 0.694336 24.0305 11.1493 24.0305 24.0002C24.0305 36.8509 35.3581 47.3059 49.2818 47.3059C55.8985 47.3059 62.1534 44.9591 66.8949 40.6982L67.009 40.5959ZM85.5906 48C78.835 48 72.4437 45.6239 67.5761 41.3055C67.4861 41.3041 67.3963 41.3013 67.3066 41.2986C62.4379 45.6216 56.0441 48 49.2837 48C34.9454 48 23.2803 37.2338 23.2803 24.0001C23.2803 10.7666 34.9454 0 49.2837 0C56.1116 0 62.5518 2.41964 67.4362 6.81668H67.4387C72.3234 2.41964 78.7633 0 85.5906 0C99.9295 0 111.595 10.7666 111.595 24.0001C111.595 37.2338 99.9295 48 85.5906 48Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.4387 7.4458C57.1556 7.4458 48.7898 14.872 48.7898 24C48.7898 33.1276 57.1556 40.5533 67.4387 40.5533C77.7223 40.5533 86.0886 33.1276 86.0886 24C86.0886 14.872 77.7223 7.4458 67.4387 7.4458ZM67.4411 41.2477C56.7433 41.2477 48.04 33.5107 48.04 24.0002C48.04 14.4894 56.7433 6.75171 67.4411 6.75171C78.1394 6.75171 86.8432 14.4894 86.8432 24.0002C86.8432 33.5107 78.1394 41.2477 67.4411 41.2477Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.982 30.0103V27.913H110.344V25.3955H116.483V23.2973H110.344V21.8285H118.982V19.4163H105.116V30.0103H118.982Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126.243 30.0103V25.3955H132.381V23.2973H126.243V21.8285H134.882V19.4163H121.015V30.0103H126.243Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.1934 26.4481H93.0279C93.1317 26.9355 93.3468 27.1183 93.67 27.3737C94.1958 27.7864 94.9475 27.9937 95.9233 27.9937C96.6522 27.9937 97.2133 27.887 97.6097 27.6746C98.0016 27.4635 98.2012 27.218 98.2012 26.9389C98.2012 26.6728 98.0154 26.4347 97.6388 26.2255C97.2667 26.0156 96.3959 25.818 95.0312 25.6317C92.7962 25.32 91.2031 24.9055 90.2501 24.3885C89.2895 23.8718 88.8091 23.2132 88.8091 22.4116C88.8091 21.8854 89.0549 21.389 89.5458 20.9211C90.038 20.4536 90.7776 20.0852 91.7625 19.8163C92.7491 19.549 94.1028 19.4153 95.8212 19.4153C97.9266 19.4153 99.5368 19.6592 100.644 20.1461C101.749 20.633 102.406 21.4084 102.618 22.4708H97.8231C97.6937 22.008 97.4252 21.847 97.0152 21.6371C96.6065 21.4262 96.0391 21.3219 95.318 21.3219C94.7236 21.3219 94.2768 21.3999 93.9749 21.5551C93.6745 21.7116 93.5251 21.9016 93.5251 22.1251C93.5251 22.2876 93.6469 22.4345 93.8954 22.5648C94.1349 22.7002 94.7038 22.8254 95.6049 22.9427C97.8336 23.241 99.4287 23.5418 100.392 23.8473C101.356 24.1527 102.057 24.5316 102.496 24.9844C102.935 25.4354 103.155 25.9418 103.155 26.5011C103.155 27.1581 102.862 27.7641 102.278 28.3184C101.693 28.8728 100.875 29.294 99.8251 29.5809C98.7761 29.8665 97.4495 30.0109 95.8533 30.0109C93.0477 30.0109 91.1053 29.6749 90.0259 29.0045C88.9465 28.3339 88.335 27.4825 88.1934 26.4481Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5029 26.1412H16.6516C16.3384 26.9099 15.8482 27.7964 15.1803 28.3123C14.5111 28.8283 13.6779 29.2166 12.6868 29.4793C11.6952 29.7405 10.4313 29.8722 8.89783 29.8722C7.03642 29.8722 5.51776 29.7125 4.33784 29.393C3.15917 29.0731 2.14071 28.5113 1.28448 27.7064C0.427741 26.9023 0 25.8723 0 24.6166C0 22.9428 0.753938 21.6564 2.25956 20.7571C3.76618 19.8586 5.89611 19.4092 8.65036 19.4092C10.8071 19.4092 12.5015 19.6672 13.7356 20.1835C14.9692 20.6991 15.9779 22.0246 16.5769 23.0928L11.8103 23.1738C11.6478 22.8665 11.4763 22.6411 11.297 22.4994C11.0004 22.2609 10.6394 22.0771 10.2109 21.9485C9.78239 21.82 9.30375 21.7557 8.77447 21.7557C7.57774 21.7557 6.65832 22.0403 6.01947 22.6094C5.53682 23.0315 5.29512 23.6956 5.29512 24.5993C5.29512 25.7195 5.58421 26.4874 6.15963 26.9023C6.7368 27.3177 7.54866 27.5255 8.59319 27.5255C9.60563 27.5255 10.4411 27.4341 10.8894 27.0212C11.4633 26.4904 11.2695 26.7812 11.5029 26.1412Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9383 24.7055C22.9383 25.7385 23.2409 26.4816 23.8439 26.934C24.4464 27.385 25.268 27.6109 26.3073 27.6109C27.3744 27.6109 28.1998 27.3899 28.7855 26.947C29.3717 26.505 29.6653 25.7112 29.6653 24.5657C29.6653 23.6014 29.3602 22.897 28.7474 22.452C28.1381 22.007 27.309 21.7849 26.2637 21.7849C25.2605 21.7849 24.4554 22.0114 23.8487 22.4629C23.2409 22.9144 22.9383 23.6614 22.9383 24.7055ZM17.9629 24.6926C17.9629 23.0174 18.6955 21.713 20.1575 20.7799C21.6235 19.8467 23.6622 19.3801 26.2748 19.3801C28.955 19.3801 31.018 19.8388 32.4695 20.7554C33.9162 21.6729 34.6428 22.9561 34.6428 24.6084C34.6428 25.8073 34.3256 26.7913 33.6943 27.5594C33.0602 28.3265 32.143 28.9238 30.9483 29.3496C29.7493 29.777 28.2583 29.9906 26.4728 29.9906C24.6571 29.9906 23.155 29.807 21.965 29.4379C20.7758 29.0695 19.8108 28.4873 19.0721 27.6885C18.3335 26.8901 17.9629 25.8913 17.9629 24.6926Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.1348 19.5474H41.5145L47.2411 25.3338V19.5474H51.6549V29.9912H47.2411L41.5473 24.2364V29.9912H37.1348V19.5474Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.4199 21.8848V27.6233H60.668C61.7299 27.6233 62.4868 27.5486 62.9359 27.3986C63.3857 27.2477 63.738 26.9848 63.9924 26.6097C64.2464 26.2348 64.3746 25.6269 64.3746 24.7867C64.3746 23.6733 64.0902 22.9113 63.5206 22.5003C62.9512 22.0903 62.0082 21.8848 60.6899 21.8848H59.4199ZM54.3262 19.4973H61.8906C63.3805 19.4973 64.5854 19.6262 65.5029 19.8845C66.4208 20.143 67.1807 20.5127 67.7805 20.9962C68.3795 21.4784 68.8125 22.0405 69.084 22.6813C69.3523 23.3221 69.4879 24.001 69.4879 24.7182C69.4879 25.8421 69.2866 26.7129 68.8872 27.3322C68.4845 27.951 67.9297 28.4703 67.2181 28.8887C66.5045 29.3064 65.7406 29.585 64.9249 29.7238C63.8082 29.9147 62.7958 30.0103 61.8906 30.0103H54.3262V19.4973Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.3587 19.4971H86.0609V25.6482C86.0609 26.258 85.9205 26.834 85.6384 27.3757C85.3596 27.9181 84.9173 28.3921 84.3166 28.7979C83.7158 29.2036 83.0848 29.4889 82.4246 29.653C81.5082 29.8826 80.4075 29.998 79.1205 29.998C78.3781 29.998 77.567 29.9629 76.6887 29.8925C75.8089 29.8217 75.077 29.6822 74.4825 29.4734C73.8941 29.2645 73.3543 28.9683 72.8633 28.5827C72.3739 28.1991 72.0384 27.8017 71.8584 27.3935C71.5658 26.737 71.4209 26.1552 71.4209 25.6482V19.4971H76.1215V25.7961C76.1215 26.3589 76.3565 26.799 76.8183 27.1161C77.2817 27.4326 77.9253 27.5911 78.7454 27.5911C79.5613 27.5911 80.2031 27.4347 80.6665 27.1228C81.1286 26.8106 81.3587 26.3688 81.3587 25.7961V19.4971Z"
      fill="#A5AEA7"
    />
  </svg>
)

export default CondusefLogo
