import React from 'react'

// eslint-disable-next-line max-lines-per-function
const BuroLogo = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="4" fill="#A5AEA7" />
    <rect
      x="2.52637"
      y="2.52661"
      width="42.9474"
      height="42.9474"
      rx="4"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0178 30.4633C18.9494 30.4633 18.8826 30.457 18.8125 30.457C18.6379 30.457 18.2066 30.4839 18.2066 30.7823C18.2066 30.9622 18.3863 31.0585 18.5335 31.0585C18.8501 31.0585 19.0178 30.8659 19.0178 30.6133V30.4633ZM18.0617 29.6949C18.2226 29.6017 18.4142 29.5354 18.6367 29.5354C19.1365 29.5354 19.3385 29.7738 19.3385 30.2364V30.9389C19.3385 31.1331 19.3453 31.2215 19.3522 31.272H19.0526V31.0526H19.0458C18.9705 31.1552 18.8353 31.3131 18.5409 31.3131C18.1644 31.3131 17.959 31.1536 17.959 30.7826C17.959 30.3579 18.4057 30.2237 18.736 30.2237C18.8627 30.2237 18.8918 30.2237 19.0167 30.2316C19.0167 29.938 18.9089 29.788 18.5854 29.788C18.4057 29.788 18.2089 29.8543 18.0788 29.9617L18.0617 29.6949Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.0509 30.467C30.9824 30.467 30.9139 30.4607 30.8455 30.4607C30.6692 30.4607 30.2379 30.4875 30.2379 30.7875C30.2379 30.9659 30.4176 31.0622 30.5648 31.0622C30.8814 31.0622 31.0509 30.8696 31.0509 30.617V30.467ZM30.0959 29.6985C30.2567 29.6054 30.4467 29.5391 30.6692 29.5391C31.169 29.5391 31.3709 29.7775 31.3709 30.2416V30.9441C31.3709 31.1368 31.3778 31.2267 31.3846 31.2757H31.0851V31.0562H31.0783C31.003 31.1589 30.8678 31.3167 30.5734 31.3167C30.1968 31.3167 29.9932 31.1573 29.9932 30.7879C29.9932 30.3616 30.4382 30.2274 30.7685 30.2274C30.8951 30.2274 30.9242 30.2274 31.0509 30.2353C31.0509 29.9416 30.9413 29.7917 30.6196 29.7917C30.4382 29.7917 30.243 29.858 30.113 29.9653L30.0959 29.6985Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.0394 30.467C39.971 30.467 39.9042 30.4607 39.8358 30.4607C39.6595 30.4607 39.2282 30.4875 39.2282 30.7875C39.2282 30.9659 39.4079 31.0622 39.5551 31.0622C39.8717 31.0622 40.0394 30.8696 40.0394 30.617V30.467ZM39.0824 29.6985C39.2433 29.6054 39.435 29.5391 39.6575 29.5391C40.1573 29.5391 40.3592 29.7775 40.3592 30.2416V30.9441C40.3592 31.1368 40.3661 31.2267 40.3729 31.2757H40.0734V31.0562H40.0665C39.9912 31.1589 39.856 31.3167 39.5617 31.3167C39.1851 31.3167 38.9814 31.1573 38.9814 30.7879C38.9814 30.3616 39.4264 30.2274 39.7568 30.2274C39.8834 30.2274 39.9125 30.2274 40.0375 30.2353C40.0375 29.9416 39.9296 29.7917 39.6061 29.7917C39.4264 29.7917 39.2296 29.858 39.1013 29.9653L39.0824 29.6985Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2566 25.0675C13.2288 25.0675 13.9237 24.7217 14.3378 24.2213C14.598 23.9055 14.7281 23.5235 14.7281 23.0751C14.7281 22.3205 14.3498 21.8058 13.5865 21.5311C13.1843 21.3843 12.6486 21.1901 11.9845 21.1901H8.83359V25.0675H12.2566ZM11.7243 20.1707C12.3953 20.1707 12.9173 20.0886 13.2904 19.9228C13.874 19.6639 14.3019 19.1966 14.3019 18.5209C14.3019 17.842 13.8569 17.3021 13.2322 17.0668C12.8813 16.9342 12.361 16.8679 11.6679 16.8679H8.83359V20.1707H11.7243ZM7.29297 15.7739H12.3111C13.6804 15.7739 14.8356 16.1386 15.4124 16.8648C15.7513 17.2943 15.9224 17.79 15.9224 18.352C15.9224 19.0088 15.4689 19.645 15.053 20.0665C14.8373 20.2876 14.3444 20.4912 13.9388 20.6744C14.5344 20.878 14.9794 21.1069 15.2755 21.3611C15.7958 21.8142 16.3007 22.3399 16.3007 23.1372C16.3007 23.8082 16.0662 24.4144 15.5921 24.9575C14.8887 25.769 13.7676 26.1747 12.229 26.1747H7.29297V15.7739Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5349 18.6353V23.4189C19.5349 23.8167 19.6016 24.1435 19.7351 24.3945C19.9816 24.8618 20.4403 25.0955 21.1129 25.0955C22.0765 25.0955 23.42 24.4577 23.42 24.0235C23.42 23.4315 23.4115 22.8331 23.4115 22.0706V18.6353H24.9347V26.1217H23.4919L23.5056 24.8902C23.3259 25.1871 23.0572 25.4381 22.7868 25.6417C22.2545 26.049 21.6092 26.2543 20.8493 26.2543C19.6649 26.2543 18.8588 25.8817 18.4292 25.1397C18.1982 24.7403 18.0801 24.2082 18.0801 23.542V18.6353H19.5349Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.7529 18.5358H29.105V19.8841C29.2146 19.6204 29.6305 19.3015 30.0635 18.9258C30.4948 18.5485 30.9928 18.3606 31.5559 18.3606C31.5816 18.3606 31.6278 18.3638 31.6911 18.3685C31.7545 18.3716 31.8606 18.3827 32.0146 18.3969V19.7815C31.929 19.7673 31.8537 19.7578 31.7818 19.7531C31.71 19.7483 31.6329 19.7452 31.5491 19.7452C30.832 19.7452 30.2826 19.9583 29.8975 20.383C29.5107 20.8077 29.3053 21.2986 29.3053 21.8528V26.1754H27.7529V18.5358Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.5988 24.2771C38.2343 24.9165 37.5599 25.2354 36.5758 25.2354C35.6824 25.2354 35.0286 24.967 34.6161 24.4286C34.2053 23.8903 33.9982 23.2193 33.9982 22.4126C33.9982 21.5727 34.2053 20.8559 34.6161 20.2607C35.0286 19.6671 35.6875 19.3687 36.5929 19.3687C37.6147 19.3687 38.3335 19.7177 38.7511 20.417C39.0147 20.8591 39.1465 21.4353 39.1465 22.1426C39.1465 22.9272 38.9651 23.6377 38.5988 24.2771ZM39.529 19.2346C38.74 18.5825 37.7764 18.2573 36.6348 18.2573C35.3598 18.2573 34.3465 18.6346 33.5901 19.3893C32.8353 20.1439 32.457 21.1591 32.457 22.4331C32.457 23.6219 32.8147 24.5707 33.5319 25.2749C34.249 25.9821 35.216 26.3342 36.4346 26.3342C37.8962 26.3342 38.9762 25.9348 39.6694 25.1375C40.3642 24.3402 40.7117 23.3298 40.7117 22.1079C40.7117 20.8433 40.318 19.885 39.529 19.2346Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.8468 15.1575L36.425 17.6629H35.333L37.0924 15.1575H38.8468Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.38425 31.0563C6.42019 31.0563 6.46298 31.0532 6.51433 31.0484C6.56567 31.0421 6.62215 31.0295 6.68034 31.009C6.73853 30.9884 6.79673 30.9584 6.85321 30.919C6.9114 30.8795 6.96274 30.8258 7.00724 30.7595C7.05345 30.6932 7.09111 30.608 7.1202 30.5069C7.14759 30.4059 7.16299 30.2843 7.16299 30.1407C7.16299 30.0017 7.1493 29.877 7.12363 29.7665C7.09624 29.6576 7.05174 29.5644 6.99013 29.4871C6.9268 29.4097 6.84807 29.3513 6.75223 29.3102C6.65296 29.2708 6.53315 29.2502 6.3911 29.2502H5.87079V31.0563H6.38425ZM6.35762 28.9895C6.71362 28.9895 6.98917 29.0811 7.18257 29.2626C7.37426 29.4458 7.47182 29.7236 7.47182 30.0978C7.47182 30.2935 7.44957 30.4656 7.40678 30.6172C7.36228 30.7687 7.29553 30.895 7.20482 30.9992C7.11411 31.1019 6.99773 31.1808 6.85738 31.2345C6.71533 31.2897 6.55102 31.3166 6.35762 31.3166H5.56348V28.9895H6.35762Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75163 30.2508C8.74478 30.0298 8.63866 29.8324 8.34771 29.8324C8.07729 29.8324 7.93181 30.0061 7.8856 30.2508H8.75163ZM7.54004 30.4454C7.54004 29.9576 7.87892 29.5977 8.35472 29.5977C8.80314 29.5977 9.06671 29.8881 9.06671 30.3476C9.06671 30.387 9.06329 30.4281 9.05816 30.4723H7.8755C7.88577 30.7991 8.03296 31.0785 8.41463 31.0785C8.65595 31.0785 8.81854 30.9791 8.89556 30.9396L9.01708 31.1496C8.92295 31.208 8.72612 31.3169 8.39922 31.3169C7.831 31.3169 7.54004 30.9396 7.54004 30.4454Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5162 30.2517C22.5076 30.0307 22.4015 29.8333 22.1105 29.8333C21.8401 29.8333 21.6946 30.007 21.6484 30.2517H22.5162ZM21.3037 30.4454C21.3037 29.9576 21.6426 29.5977 22.1201 29.5977C22.5685 29.5977 22.8304 29.8881 22.8304 30.3476C22.8304 30.387 22.827 30.4281 22.8235 30.4723H21.6392C21.6494 30.7991 21.7983 31.0785 22.1783 31.0785C22.4196 31.0785 22.5822 30.9791 22.6609 30.9396L22.7825 31.1496C22.6866 31.208 22.4898 31.3169 22.1629 31.3169C21.5947 31.3169 21.3037 30.9396 21.3037 30.4454Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.2994 30.2517C37.2908 30.0307 37.1847 29.8333 36.8937 29.8333C36.6233 29.8333 36.4778 30.007 36.4316 30.2517H37.2994ZM36.0869 30.4454C36.0869 29.9576 36.4258 29.5977 36.9033 29.5977C37.3517 29.5977 37.6136 29.8881 37.6136 30.3476C37.6136 30.387 37.6102 30.4281 37.6067 30.4723H36.4224C36.4326 30.7991 36.5815 31.0785 36.9615 31.0785C37.2028 31.0785 37.3654 30.9791 37.4441 30.9396L37.5657 31.1496C37.4698 31.208 37.273 31.3169 36.9461 31.3169C36.3779 31.3169 36.0869 30.9396 36.0869 30.4454Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4302 28.9729H11.7498V29.2413H10.7502V29.9533H11.6813V30.2185H10.7502V31.0584H11.8165V31.2952H10.4302V28.9729Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1344 29.6199H12.4596V29.852C12.6205 29.6752 12.8293 29.5789 13.0672 29.5789C13.4643 29.5789 13.62 29.8109 13.62 30.1046V31.295H13.2897V30.163C13.2897 29.9609 13.2127 29.8362 12.9748 29.8362C12.7711 29.8362 12.5863 29.9751 12.4596 30.1251V31.295H12.1344V29.6199Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.1579 29.6199H28.4831V29.852C28.644 29.6752 28.8545 29.5789 29.0924 29.5789C29.4877 29.5789 29.6435 29.8109 29.6435 30.1046V31.295H29.3132V30.163C29.3132 29.9609 29.2361 29.8362 28.9982 29.8362C28.7963 29.8362 28.6097 29.9751 28.4831 30.1251V31.295H28.1579V29.6199Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.8309 29.6199H32.1578V29.852C32.3169 29.6752 32.5274 29.5789 32.7653 29.5789C33.1624 29.5789 33.3182 29.8109 33.3182 30.1046V31.295H32.9878V30.163C32.9878 29.9609 32.9091 29.8362 32.6712 29.8362C32.4693 29.8362 32.2827 29.9751 32.1578 30.1251V31.295H31.8309V29.6199Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9166 29.6237V29.8684H14.4442V30.9136C14.4442 30.9467 14.4476 30.972 14.4528 30.9925C14.4562 31.0115 14.4665 31.0272 14.4819 31.0383C14.4973 31.0493 14.5195 31.0557 14.5452 31.0588C14.5726 31.0636 14.6085 31.0651 14.6513 31.0651H14.9166V31.3083H14.5674C14.4955 31.3083 14.4356 31.3035 14.3826 31.294C14.333 31.2846 14.2919 31.2672 14.2594 31.2404C14.2303 31.2135 14.2063 31.1772 14.1909 31.1299C14.1738 31.0809 14.1686 31.0178 14.1686 30.9404V29.8684H13.8828V29.6237H14.1686V28.9543H14.4442V29.6237H14.9166Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2612 31.2939H15.5676V29.5999H15.2612V31.2939ZM15.2051 29.1102C15.2051 28.9981 15.2975 28.9097 15.4156 28.9097C15.532 28.9097 15.6261 28.9981 15.6261 29.1102C15.6261 29.2191 15.532 29.3107 15.4156 29.3107C15.2975 29.3107 15.2051 29.2191 15.2051 29.1102Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.3512 31.2942H27.6593V29.6002H27.3512V31.2942ZM27.29 29.1102C27.29 28.9981 27.3825 28.9097 27.5006 28.9097C27.6187 28.9097 27.7111 28.9981 27.7111 29.1102C27.7111 29.2191 27.6187 29.3107 27.5006 29.3107C27.3825 29.3107 27.29 29.2191 27.29 29.1102Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.3846 31.2939H35.691V29.5999H35.3846V31.2939ZM35.3281 29.1102C35.3281 28.9981 35.4188 28.9097 35.5386 28.9097C35.655 28.9097 35.7474 28.9981 35.7474 29.1102C35.7474 29.2191 35.655 29.3107 35.5386 29.3107C35.4188 29.3107 35.3281 29.2191 35.3281 29.1102Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3129 30.6832C16.3352 30.7574 16.3643 30.8238 16.4088 30.8837C16.4498 30.9422 16.5029 30.9879 16.5696 31.0243C16.6347 31.059 16.7117 31.0764 16.8024 31.0764C16.8948 31.0764 16.9736 31.0574 17.0386 31.0195C17.1036 30.9832 17.1567 30.9358 17.1995 30.8759C17.2388 30.8159 17.2696 30.748 17.2868 30.6722C17.3056 30.5948 17.3141 30.5191 17.3141 30.4401C17.3141 30.3564 17.3056 30.2775 17.2851 30.2002C17.2662 30.1244 17.2354 30.0565 17.1943 29.9965C17.1498 29.9381 17.0968 29.8907 17.0283 29.856C16.9616 29.8197 16.8811 29.8007 16.7853 29.8007C16.6929 29.8007 16.6141 29.8197 16.5491 29.8576C16.4841 29.8939 16.431 29.9428 16.3934 30.0028C16.3523 30.0644 16.3266 30.1338 16.3078 30.2128C16.2907 30.2901 16.2821 30.3707 16.2821 30.4528C16.2821 30.5317 16.2924 30.6075 16.3129 30.6832ZM17.3047 31.2816V31.0526H17.2978C17.2533 31.1458 17.1815 31.2137 17.0839 31.2547C16.9863 31.2958 16.8785 31.3163 16.7621 31.3163C16.6321 31.3163 16.5191 31.2926 16.4215 31.2453C16.3274 31.1979 16.247 31.1332 16.1836 31.0526C16.1186 30.9721 16.0707 30.879 16.0382 30.7732C16.0056 30.6659 15.9902 30.5522 15.9902 30.4338C15.9902 30.3138 16.0039 30.2017 16.0364 30.0943C16.0673 29.9886 16.1152 29.8954 16.1802 29.8165C16.2435 29.736 16.324 29.6728 16.4181 29.627C16.5157 29.5797 16.6286 29.5576 16.7553 29.5576C16.7981 29.5576 16.846 29.5607 16.8956 29.5702C16.9453 29.5781 16.9932 29.5939 17.0445 29.6144C17.0942 29.6334 17.1404 29.6618 17.1866 29.6949C17.2294 29.7297 17.2687 29.7707 17.2978 29.8212H17.3047V28.8171H17.5802V31.2816H17.3047Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.819 30.6832C19.8395 30.7574 19.8703 30.8238 19.9131 30.8837C19.9542 30.9422 20.0089 30.9879 20.074 31.0243C20.1407 31.059 20.2177 31.0764 20.3067 31.0764C20.4009 31.0764 20.4796 31.0574 20.5446 31.0195C20.6097 30.9832 20.661 30.9358 20.7038 30.8759C20.7449 30.8159 20.774 30.748 20.7928 30.6722C20.8116 30.5948 20.8202 30.5191 20.8202 30.4401C20.8202 30.3564 20.8099 30.2775 20.7911 30.2002C20.7723 30.1244 20.7398 30.0565 20.6987 29.9965C20.6559 29.9381 20.6011 29.8907 20.5344 29.856C20.4676 29.8197 20.3855 29.8007 20.2896 29.8007C20.1989 29.8007 20.1185 29.8197 20.0552 29.8576C19.9901 29.8939 19.9371 29.9428 19.8977 30.0028C19.8583 30.0644 19.8309 30.1338 19.8138 30.2128C19.7967 30.2901 19.7864 30.3707 19.7864 30.4528C19.7864 30.5317 19.7984 30.6075 19.819 30.6832ZM20.8079 31.2816V31.0526H20.801C20.7565 31.1458 20.6846 31.2137 20.5871 31.2547C20.4912 31.2958 20.3834 31.3163 20.267 31.3163C20.1369 31.3163 20.0223 31.2926 19.9264 31.2453C19.8306 31.1979 19.7501 31.1332 19.6868 31.0526C19.6235 30.9721 19.5738 30.879 19.543 30.7732C19.5088 30.6659 19.4951 30.5522 19.4951 30.4338C19.4951 30.3138 19.5088 30.2017 19.5413 30.0943C19.5704 29.9886 19.6201 29.8954 19.6834 29.8165C19.7467 29.736 19.8272 29.6728 19.923 29.627C20.0188 29.5797 20.1335 29.5576 20.2602 29.5576C20.303 29.5576 20.3509 29.5607 20.3988 29.5702C20.4484 29.5781 20.4981 29.5939 20.5494 29.6144C20.599 29.6334 20.6453 29.6618 20.6898 29.6949C20.7343 29.7297 20.7719 29.7707 20.801 29.8212H20.8079V28.8171H21.0834V31.2816H20.8079Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9959 31.1601L23.1191 30.9454C23.1841 30.9896 23.3416 31.078 23.5436 31.078C23.7507 31.078 23.8807 31.0007 23.8807 30.8617C23.8807 30.7181 23.7421 30.6596 23.4905 30.5539C23.2269 30.4449 23.0335 30.3265 23.0335 30.0534C23.0335 29.7913 23.2475 29.594 23.5983 29.594C23.8842 29.594 24.0587 29.7029 24.1152 29.744L23.98 29.9524C23.9184 29.9082 23.7883 29.8324 23.6018 29.8324C23.4203 29.8324 23.3228 29.9113 23.3228 30.0408C23.3228 30.186 23.4648 30.2444 23.6599 30.3202C23.9338 30.4323 24.1785 30.5476 24.1785 30.8238C24.1785 31.1285 23.9372 31.3164 23.5521 31.3164C23.2954 31.3164 23.102 31.2312 22.9959 31.1601Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.699 31.1601L40.8222 30.9454C40.8856 30.9896 41.043 31.078 41.2467 31.078C41.4538 31.078 41.5839 31.0007 41.5839 30.8617C41.5839 30.7181 41.4435 30.6596 41.1936 30.5539C40.9301 30.4449 40.7367 30.3265 40.7367 30.0534C40.7367 29.7913 40.9506 29.594 41.3015 29.594C41.5873 29.594 41.7601 29.7029 41.8183 29.744L41.6831 29.9524C41.6215 29.9082 41.4897 29.8324 41.3049 29.8324C41.1217 29.8324 41.0259 29.9113 41.0259 30.0408C41.0259 30.186 41.168 30.2444 41.3614 30.3202C41.6369 30.4323 41.8817 30.5476 41.8817 30.8238C41.8817 31.1285 41.6403 31.3164 41.2535 31.3164C40.9985 31.3164 40.8051 31.2312 40.699 31.1601Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7236 28.9309H26.9918V29.2009H26.0471V29.9824H26.9473V30.2444H26.0471V31.2959H25.7236V28.9309Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.6302 29.8381C34.56 29.7781 34.4676 29.7465 34.3512 29.7465C34.2502 29.7465 34.1664 29.767 34.0996 29.8049C34.0329 29.8428 33.9798 29.8933 33.9404 29.9565C33.8994 30.0196 33.872 30.0922 33.8531 30.1743C33.836 30.2564 33.8275 30.3417 33.8275 30.4301C33.8275 30.5106 33.836 30.5895 33.8549 30.6653C33.8737 30.7411 33.9011 30.8106 33.9421 30.869C33.9798 30.929 34.0311 30.9763 34.0945 31.0127C34.1578 31.049 34.2314 31.0663 34.317 31.0663C34.4539 31.0663 34.5617 31.03 34.637 30.9558C34.714 30.8816 34.762 30.7758 34.7791 30.6416H35.0615C35.0307 30.8579 34.9537 31.0237 34.8287 31.1405C34.7055 31.2574 34.5343 31.3173 34.3204 31.3173C34.1937 31.3173 34.0808 31.2952 33.9815 31.2526C33.8822 31.21 33.8018 31.15 33.7351 31.0695C33.6683 30.9905 33.6187 30.8974 33.5862 30.7885C33.5519 30.6795 33.5348 30.5596 33.5348 30.4301C33.5348 30.2991 33.5519 30.1759 33.5844 30.0638C33.617 29.9486 33.6666 29.8507 33.7333 29.7654C33.7984 29.6818 33.8822 29.6155 33.9798 29.5665C34.0808 29.5176 34.1955 29.4939 34.3272 29.4939C34.4231 29.4939 34.5121 29.5065 34.5942 29.5302C34.6781 29.5523 34.7517 29.5902 34.815 29.6391C34.8801 29.6881 34.9331 29.7512 34.9725 29.827C35.0153 29.9028 35.0409 29.9912 35.0512 30.0954H34.7654C34.7431 29.9849 34.6986 29.8996 34.6302 29.8381Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.9981 29.605H38.2736V29.8971C38.2959 29.8387 38.3524 29.7708 38.4397 29.6887C38.5287 29.6082 38.6296 29.5671 38.746 29.5671C38.7494 29.5671 38.7597 29.5687 38.7717 29.5687C38.7854 29.5703 38.8076 29.5719 38.8384 29.575V29.8734C38.8213 29.8703 38.8059 29.8687 38.7905 29.8687C38.7768 29.8671 38.7597 29.8671 38.7443 29.8671C38.5971 29.8671 38.4842 29.9129 38.4054 30.0045C38.3267 30.096 38.289 30.2018 38.289 30.3218V31.2911H37.9981V29.605Z"
      fill="#A5AEA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.05371 32.0867H42.9484V31.7078H5.05371V32.0867Z"
      fill="#A5AEA7"
    />
  </svg>
)

export default BuroLogo
