import React from 'react'

export const Forbes = () => (
  <svg
    width="96"
    height="24"
    viewBox="0 0 96 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.3613 7.81495C55.5784 7.15303 56.7955 6.83274 58.3329 6.83274C62.0909 6.83274 64.9521 10.121 64.9307 14.6477C64.9307 20.3701 61.4076 24 55.5784 24C53.4859 24 51.3934 23.6797 49.4076 23.2313C49.5144 20.9253 49.6211 6.83274 49.4076 4.18505C49.1941 2.86121 48.8525 2.30605 47.8702 2.09253L47.1016 1.98577V1.32384L54.4681 0C54.3613 1.11032 54.3613 4.29181 54.3613 7.81495ZM54.4908 22.6761C54.8325 22.7829 55.3663 22.7829 55.8147 22.7829C58.1207 22.7829 59.7648 19.3665 59.7648 14.9893C59.7648 11.4662 58.441 8.60495 56.0282 8.60495C55.4944 8.60495 55.046 8.71171 54.3841 8.92523C54.2773 14.6477 54.3841 21.1388 54.4908 22.6761Z"
      fill="#6F7974"
    />
    <path
      d="M19.3879 0.448486H0V1.43069L1.11032 1.53745C2.54093 1.75098 3.09609 2.64777 3.30961 4.71895C3.62989 8.69048 3.52313 15.8435 3.30961 19.2385C3.09609 21.3311 2.54093 22.3133 1.11032 22.42L0 22.6335V23.509H12.427V22.6335L11.1032 22.42C9.6726 22.3133 9.11744 21.3097 8.90392 19.2385C8.79715 17.8079 8.69039 15.5019 8.69039 12.6407L11.3381 12.7474C12.9822 12.7474 13.7509 14.0713 14.0925 15.9289H15.0747V8.15667H14.0925C13.7722 10.0357 12.9822 11.3382 11.3381 11.3382L8.69039 11.4449C8.69039 7.70827 8.79715 4.18514 8.90392 2.09261H12.7473C15.7153 2.09261 17.2527 3.97162 18.363 7.25987L19.4733 6.93959L19.3879 0.448486Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.908 15.3095C33.908 10.3558 31.1536 6.61914 25.6447 6.61914C20.1571 6.61914 17.061 10.5693 17.061 15.3095C17.061 20.2633 19.8155 23.9999 25.3244 23.9999C30.8333 23.9999 33.908 20.0497 33.908 15.3095ZM22.2485 15.3095C22.2485 11.0177 22.9105 7.70808 25.3233 7.70808C27.6293 7.70808 28.7396 11.1245 28.7396 15.3095C28.7396 19.6013 28.0991 22.9109 25.5581 22.9109C23.3589 22.9109 22.2485 19.4946 22.2485 15.3095Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.8646 6.61914C69.2489 6.61914 66.3877 10.911 66.3877 15.4163C66.3877 20.4768 69.9108 23.9999 74.8859 23.9999C77.9606 23.9999 80.3735 22.4626 81.4838 20.9252L81.0354 20.37C79.9464 21.1387 79.0496 21.5871 77.2987 21.5871C73.3485 21.5871 71.3628 18.6191 71.4695 14.5409H81.6973C81.6973 10.0355 79.4767 6.61914 74.8646 6.61914ZM76.6143 13.217L71.447 13.3237C71.5538 10.7828 72.536 7.70808 74.415 7.70808C76.294 7.70808 76.6143 11.0177 76.6143 13.217Z"
      fill="#6F7974"
    />
    <path
      d="M94.7856 7.49459C93.4618 6.93943 91.7109 6.61914 89.7251 6.61914C85.6469 6.61914 83.1273 9.03195 83.1273 11.8932C83.1273 14.7544 85.0063 15.9715 87.6326 16.8469C90.3871 17.8291 91.1558 18.5978 91.1558 19.9216C91.1558 21.2455 90.1735 22.4626 88.4013 22.4626C86.3088 22.4626 84.7714 21.2455 83.5543 17.9572L82.7856 18.1707L82.8924 23.0177C84.2162 23.5729 86.6291 23.9999 88.7216 23.9999C93.0134 23.9999 95.7679 21.8006 95.7679 18.3843C95.7679 16.0782 94.5508 14.7544 91.6896 13.6441C88.6148 12.427 87.5045 11.6583 87.5045 10.2277C87.5045 8.79708 88.4867 7.81487 89.8106 7.81487C91.7963 7.81487 93.1202 9.03195 94.1024 11.9999L94.8711 11.7864L94.7856 7.49459Z"
      fill="#6F7974"
    />
    <path
      d="M48.2115 7.04629C46.3325 5.93597 43.0442 6.49113 41.272 10.4627L41.3788 6.61925L34.0122 8.04985V8.71177L34.7809 8.81853C35.7631 8.9253 36.2115 9.48046 36.3183 10.9111C36.5318 13.5587 36.425 18.1708 36.3183 20.4769C36.2115 21.8007 35.7631 22.4627 34.7809 22.5694L34.0122 22.6762V23.5516H44.24V22.6762L42.9161 22.5694C41.8058 22.4627 41.4855 21.8007 41.3788 20.4769C41.1652 18.3844 41.1652 14.1993 41.272 11.5516C41.8272 10.7829 44.24 10.121 46.4393 11.5516L48.2115 7.04629Z"
      fill="#6F7974"
    />
  </svg>
)
