import { Forbes } from 'icons/logos/media/Forbes'
import { Publimetro } from 'icons/logos/media/Publimetro'
import { Economista } from 'icons/logos/media/Economista'
import SoftBank from 'icons/logos/investors/SoftBank'
import Santander from 'icons/logos/investors/Santander'
import KasZek from 'icons/logos/investors/KasZek'
import Endeavor from 'icons/logos/investors/Endeavor'
import BuroLogo from 'icons/logos/endorsing/Buro'
import BuroLogoAbout from 'icons/logos/endorsing/BuroAbout'
import CondusefLogo from 'icons/logos/endorsing/Condusef'
import CNBVLogo from 'icons/logos/endorsing/CNBV'

export const MediaMentions = [Forbes, Publimetro, Economista]
export const Investors = [SoftBank, Santander, KasZek, Endeavor]
export const Endorsing = [BuroLogo, CondusefLogo, CNBVLogo]
export const EndorsingAbout = [BuroLogoAbout, CondusefLogo, CNBVLogo]
