import React from 'react'

const Endeavor = () => (
  <svg
    width="194"
    height="32"
    viewBox="0 0 194 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M145.059 7L136.844 25H134.544L126 7H130.381L136.515 20.8824L142.54 7H145.059Z"
      fill="#6F7974"
    />
    <path
      d="M72.3798 15.7632C72.3798 11.8553 69.9561 9.01316 64.2274 8.89474C63.1258 8.89474 62.1343 8.89474 61.0326 9.01316V21.0921C61.0326 22.9868 61.8038 23.3421 64.4478 23.3421C68.9646 23.2237 72.3798 20.1447 72.3798 15.7632ZM76.2356 15.5263C76.2356 20.5 72.3798 25 64.5579 25H57.1768V7H65.5494C73.1509 7 76.2356 11.5 76.2356 15.5263Z"
      fill="#6F7974"
    />
    <path
      d="M23.5805 13.2658C23.5805 11.2152 21.4368 8.82278 17.9392 8.82278C14.6673 8.82278 12.0723 11.3291 11.9595 14.6329H21.2112C23.0164 14.6329 23.5805 14.1772 23.5805 13.2658ZM27.5294 14.8608C27.5294 16 27.0781 16.3418 26.0627 16.3418H11.8467C12.1851 20.7848 15.7956 23.0633 19.8573 23.0633C22.2266 23.0633 24.5959 22.4937 26.7396 21.5823L27.4166 23.0633C24.4831 24.3165 21.324 25 18.1649 25C13.0878 25 8.01061 22.3797 7.78496 16.2278H0L0.789778 14.519H7.89778C8.68756 9.8481 13.4262 7 17.8264 7C25.0473 7.11392 27.5294 12.2405 27.5294 14.8608Z"
      fill="#6F7974"
    />
    <path
      d="M165.552 16C165.552 11.7848 162.904 8.70886 158.824 8.70886C155.184 8.59494 152.206 11.6709 152.096 15.4304C152.096 15.5443 152.096 15.6582 152.096 15.7722C152.096 20.2152 154.743 23.2911 158.934 23.2911C162.463 23.2911 165.552 20.6709 165.552 16ZM169.412 16C169.412 21.6962 164.007 25 158.824 25C153.64 25 148.235 21.6962 148.235 16C148.235 10.3038 153.64 7 158.824 7C164.007 7 169.412 10.3038 169.412 16Z"
      fill="#6F7974"
    />
    <path
      d="M51.8828 25H49.3584L36.2974 11.5V25H33.8828V7H37.5048L49.3584 19.4342V7H51.8828V25Z"
      fill="#6F7974"
    />
    <path
      d="M118.939 15.2548V18.9236C118.939 21.5605 116.458 23.051 113.544 23.051C111.062 23.051 109.66 21.9045 109.66 19.7261C109.66 15.9427 115.918 15.1401 118.831 15.1401H118.939V15.2548ZM118.616 13.7643C112.465 13.7643 105.883 15.5987 105.883 19.9554C105.883 22.707 107.933 25 111.71 25C114.299 24.8854 116.781 24.1975 119.047 22.9363V24.6561H122.824V14.3376C122.824 8.71975 119.803 7 115.271 7C112.465 7 109.552 7.57325 106.962 8.71975L107.609 10.4395C109.552 9.40764 111.71 8.8344 113.976 8.71975C117.752 8.71975 119.155 11.0127 119.155 13.7643H118.616Z"
      fill="#6F7974"
    />
    <path
      d="M177.538 15.8831V8.75325C178.316 8.63636 179.094 8.63636 179.872 8.63636C183.428 8.63636 184.762 9.80519 184.762 12.1429C184.762 14.9481 182.206 15.8831 178.204 15.8831H177.538ZM173.647 7V24.6494H177.538V17.5195H179.538C183.428 21.6104 186.763 24.4156 193.209 25L193.765 23.8312C189.097 22.5455 185.651 19.039 183.54 16.7013C186.096 16.1169 188.652 14.7143 188.652 11.5584C188.652 9.22078 186.652 7 180.761 7H173.647Z"
      fill="#6F7974"
    />
    <path
      d="M96.864 13.19C96.864 11.1267 94.7828 8.71945 91.3873 8.71945C88.2108 8.71945 85.6916 11.2413 85.582 14.5656H94.5638C96.2068 14.5656 96.864 14.1071 96.864 13.19ZM81.5293 15.8265C81.5293 10.7828 86.2392 7 91.2778 7C98.1784 7 100.588 12.1584 100.588 14.7948C100.588 15.9411 100.15 16.285 99.1642 16.285H85.4725C85.8011 20.7556 89.3062 23.0482 93.2494 23.0482C95.5496 23.0482 97.8498 22.4751 99.9309 21.558L100.588 23.0482C97.7402 24.3091 94.6733 24.9969 91.6064 24.9969C86.2392 25.1116 81.5293 22.0165 81.5293 15.8265Z"
      fill="#6F7974"
    />
  </svg>
)

export default Endeavor
